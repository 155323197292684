exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-checkout-checkout-tsx": () => import("./../../../src/pages/checkout/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-checkout-tsx" */),
  "component---src-pages-checkout-confirmation-tsx": () => import("./../../../src/pages/checkout/confirmation.tsx" /* webpackChunkName: "component---src-pages-checkout-confirmation-tsx" */),
  "component---src-pages-checkout-searchresult-tsx": () => import("./../../../src/pages/checkout/searchresult.tsx" /* webpackChunkName: "component---src-pages-checkout-searchresult-tsx" */),
  "component---src-pages-en-404-tsx": () => import("./../../../src/pages/en/404.tsx" /* webpackChunkName: "component---src-pages-en-404-tsx" */),
  "component---src-pages-en-aboutus-tsx": () => import("./../../../src/pages/en/aboutus.tsx" /* webpackChunkName: "component---src-pages-en-aboutus-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-meetingroom-tsx": () => import("./../../../src/pages/en/meetingroom.tsx" /* webpackChunkName: "component---src-pages-en-meetingroom-tsx" */),
  "component---src-pages-en-news-tsx": () => import("./../../../src/pages/en/news.tsx" /* webpackChunkName: "component---src-pages-en-news-tsx" */),
  "component---src-pages-en-spaces-tsx": () => import("./../../../src/pages/en/spaces.tsx" /* webpackChunkName: "component---src-pages-en-spaces-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nieuws-tsx": () => import("./../../../src/pages/nieuws.tsx" /* webpackChunkName: "component---src-pages-nieuws-tsx" */),
  "component---src-pages-overons-tsx": () => import("./../../../src/pages/overons.tsx" /* webpackChunkName: "component---src-pages-overons-tsx" */),
  "component---src-pages-ruimtes-tsx": () => import("./../../../src/pages/ruimtes.tsx" /* webpackChunkName: "component---src-pages-ruimtes-tsx" */),
  "component---src-pages-vergaderzaal-tsx": () => import("./../../../src/pages/vergaderzaal.tsx" /* webpackChunkName: "component---src-pages-vergaderzaal-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-spaceunit-tsx": () => import("./../../../src/templates/spaceunit.tsx" /* webpackChunkName: "component---src-templates-spaceunit-tsx" */)
}

